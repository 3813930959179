import revive_payload_client_4sVQNw7RlN from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/data-test/workspace/Front_yb-switcher-website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/data-test/workspace/Front_yb-switcher-website/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/data-test/workspace/Front_yb-switcher-website/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/data-test/workspace/Front_yb-switcher-website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import yb_guard_client_Gb9aT0eFMr from "/data-test/workspace/Front_yb-switcher-website/plugins/yb-guard.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  yb_guard_client_Gb9aT0eFMr
]