
import { updateAppConfig } from '#app'
import { defuFn } from '/data-test/workspace/Front_yb-switcher-website/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "a6c070ce-d160-41cc-a81a-8efe34d48e29"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
