// 默认缓存期限为7天
// const PRE_FIX_KEY = 'PRE_FIX_KEY'
const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7
// const getKey = (key: string) => {
//   return `${PRE_FIX_KEY}${key}`.toUpperCase()
// }

export const getFileType = (url: string): string | undefined => {
  if (!url) return ''
  try {
    const newUrl = decodeURIComponent(url)
    const name =
      newUrl.lastIndexOf('.') === -1
        ? newUrl
        : newUrl.substring(newUrl.lastIndexOf('.') + 1)
    return name
  } catch (error) {
    console.log('error: ', error)
  }
}

export const $localStorage = {
  /**
   *@params expires 过期时间,单位毫秒
   */
  setItem (key: string, value: any, expires?: number) {
    const data = {
      value: value,
      time: Date.now(),
      expires: expires
    }
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(data))
    }
  },
  getItem (key: string) {
    let dataStr = null
    if (typeof window !== 'undefined') {
      dataStr = window.localStorage.getItem(key)
    }
    if (!dataStr) return dataStr
    const data = JSON.parse(dataStr)
    if (data.expires && Date.now() - data.time > data.expires) {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key)
      }
      return null
    }
    return data.value || data
  },
  removeItem (key: string) {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(key)
    }
  }
}

export const $cookie = {
  /**
   * 设置cookie
   * @param {string} name cookie 名称
   * @param {*} value cookie 值
   * @param {number=} expire 过期时间
   * 如果过期时间为设置，默认关闭浏览器自动删除
   * @example
   */
  setCookie (
    name: string,
    value: any,
    expire: number | null = DEFAULT_CACHE_TIME,
    domain?: string
  ) {
    if (typeof document !== 'undefined') {
      document.cookie = `${name}=${value};Max-Age=${expire}${
        domain ? `;domain=${domain};path=/` : ''
      }`
    }
  },

  /**
   * 根据名字获取cookie值
   * @param name
   */
  getCookie (name: string): string {
    if (typeof document !== 'undefined') {
      const cookieArr = document.cookie.split('; ')
      for (let i = 0, length = cookieArr.length; i < length; i++) {
        const kv = cookieArr[i].split('=')
        if (kv[0] === name) {
          return kv[1]
        }
      }
    }
    return ''
  },

  /**
   * 根据名字删除指定的cookie
   * @param {string} key
   */
  removeCookie (key: string, domain?: string) {
    this.setCookie(key, 1, -1, domain)
  },

  /**
   * 清空cookie，使所有cookie失效
   */
  clearCookie (): void {
    if (typeof document !== 'undefined') {
      const keys = document.cookie.match(/[^ =;]+(?==)/g)
      if (keys) {
        for (let i = keys.length; i--; ) {
          document.cookie = `${keys[i]}=0;expire=${new Date(0).toUTCString()}`
        }
      }
    }
  }
}
export const $sessionStorage = {
  setItem (key: string, value: any) {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(key, value)
    }
  },
  getItem (key: string) {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem(key)
    }
  },
  removeItem (key: string) {
    if (typeof window !== 'undefined') {
      window.sessionStorage.removeItem(key)
    }
  }
}

export const getUserInfo = () => {
  return $localStorage.getItem('userInfoState')
}

export const getLoginState = () => {
  return $localStorage.getItem('loginState')
}

export const removeLoginDataFn = () => {
  $localStorage.removeItem('loginState')
  $localStorage.removeItem('userInfoState')
  $cookie.removeCookie('switcher_login_state', '.100mix.cn')
}

// 跳转免费体验
export const goFreeExperience = () => {
  const userInfo = getUserInfo()
  const router = useRouter()
  if (!userInfo) {
    router.push({
      path: '/login',
      query: {
        type: 'register'
      }
    })
  } else {
    const env = useRuntimeConfig().public.env
    let url = ''
    if (env === 'development') {
      // 开发
      url = `https://switcher.100url.cn/#/management/index`
    } else if (env === 'test') {
      // 测试
      url = `https://switcher.test.100mix.cn/#/management/index`
    } else {
      // 线上
      url = `https://console.100mix.cn/#/management/index`
    }
    if (typeof window !== 'undefined') {
      window.open(url)
    }
  }
}
