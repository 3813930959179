import { default as aboutAmj7IZDIFjMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/about.vue?macro=true";
import { default as detail4bRxCeozf5Meta } from "/data-test/workspace/Front_yb-switcher-website/pages/case/detail.vue?macro=true";
import { default as listhiliblxZyPMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/case/list.vue?macro=true";
import { default as indexSaFDQ1xKrWMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/index.vue?macro=true";
import { default as informationdvIfCwkkqTMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/information.vue?macro=true";
import { default as loginy0mBcZQO1iMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/login.vue?macro=true";
import { default as materialHZMxjpQYWqMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/material.vue?macro=true";
import { default as healthIUjxMetQnNMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/solution/health.vue?macro=true";
import { default as playYUbybGM5zvMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/solution/play.vue?macro=true";
import { default as sciencerERCfdHlRxMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/solution/science.vue?macro=true";
import { default as tranUytcVuy99gMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/solution/tran.vue?macro=true";
import { default as versioneDOm0CGHqTMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/version.vue?macro=true";
import { default as detailNd0PkFADMpMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/whitepage/detail.vue?macro=true";
import { default as listiTCMBmhwBAMeta } from "/data-test/workspace/Front_yb-switcher-website/pages/whitepage/list.vue?macro=true";
export default [
  {
    name: aboutAmj7IZDIFjMeta?.name ?? "about",
    path: aboutAmj7IZDIFjMeta?.path ?? "/about",
    meta: aboutAmj7IZDIFjMeta || {},
    alias: aboutAmj7IZDIFjMeta?.alias || [],
    redirect: aboutAmj7IZDIFjMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: detail4bRxCeozf5Meta?.name ?? "case-detail",
    path: detail4bRxCeozf5Meta?.path ?? "/case/detail",
    meta: detail4bRxCeozf5Meta || {},
    alias: detail4bRxCeozf5Meta?.alias || [],
    redirect: detail4bRxCeozf5Meta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/case/detail.vue").then(m => m.default || m)
  },
  {
    name: listhiliblxZyPMeta?.name ?? "case-list",
    path: listhiliblxZyPMeta?.path ?? "/case/list",
    meta: listhiliblxZyPMeta || {},
    alias: listhiliblxZyPMeta?.alias || [],
    redirect: listhiliblxZyPMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/case/list.vue").then(m => m.default || m)
  },
  {
    name: indexSaFDQ1xKrWMeta?.name ?? "index",
    path: indexSaFDQ1xKrWMeta?.path ?? "/",
    meta: indexSaFDQ1xKrWMeta || {},
    alias: indexSaFDQ1xKrWMeta?.alias || [],
    redirect: indexSaFDQ1xKrWMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: informationdvIfCwkkqTMeta?.name ?? "information",
    path: informationdvIfCwkkqTMeta?.path ?? "/information",
    meta: informationdvIfCwkkqTMeta || {},
    alias: informationdvIfCwkkqTMeta?.alias || [],
    redirect: informationdvIfCwkkqTMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/information.vue").then(m => m.default || m)
  },
  {
    name: loginy0mBcZQO1iMeta?.name ?? "login",
    path: loginy0mBcZQO1iMeta?.path ?? "/login",
    meta: loginy0mBcZQO1iMeta || {},
    alias: loginy0mBcZQO1iMeta?.alias || [],
    redirect: loginy0mBcZQO1iMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: materialHZMxjpQYWqMeta?.name ?? "material",
    path: materialHZMxjpQYWqMeta?.path ?? "/material",
    meta: materialHZMxjpQYWqMeta || {},
    alias: materialHZMxjpQYWqMeta?.alias || [],
    redirect: materialHZMxjpQYWqMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/material.vue").then(m => m.default || m)
  },
  {
    name: healthIUjxMetQnNMeta?.name ?? "solution-health",
    path: healthIUjxMetQnNMeta?.path ?? "/solution/health",
    meta: healthIUjxMetQnNMeta || {},
    alias: healthIUjxMetQnNMeta?.alias || [],
    redirect: healthIUjxMetQnNMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/solution/health.vue").then(m => m.default || m)
  },
  {
    name: playYUbybGM5zvMeta?.name ?? "solution-play",
    path: playYUbybGM5zvMeta?.path ?? "/solution/play",
    meta: playYUbybGM5zvMeta || {},
    alias: playYUbybGM5zvMeta?.alias || [],
    redirect: playYUbybGM5zvMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/solution/play.vue").then(m => m.default || m)
  },
  {
    name: sciencerERCfdHlRxMeta?.name ?? "solution-science",
    path: sciencerERCfdHlRxMeta?.path ?? "/solution/science",
    meta: sciencerERCfdHlRxMeta || {},
    alias: sciencerERCfdHlRxMeta?.alias || [],
    redirect: sciencerERCfdHlRxMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/solution/science.vue").then(m => m.default || m)
  },
  {
    name: tranUytcVuy99gMeta?.name ?? "solution-tran",
    path: tranUytcVuy99gMeta?.path ?? "/solution/tran",
    meta: tranUytcVuy99gMeta || {},
    alias: tranUytcVuy99gMeta?.alias || [],
    redirect: tranUytcVuy99gMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/solution/tran.vue").then(m => m.default || m)
  },
  {
    name: versioneDOm0CGHqTMeta?.name ?? "version",
    path: versioneDOm0CGHqTMeta?.path ?? "/version",
    meta: versioneDOm0CGHqTMeta || {},
    alias: versioneDOm0CGHqTMeta?.alias || [],
    redirect: versioneDOm0CGHqTMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/version.vue").then(m => m.default || m)
  },
  {
    name: detailNd0PkFADMpMeta?.name ?? "whitepage-detail",
    path: detailNd0PkFADMpMeta?.path ?? "/whitepage/detail",
    meta: detailNd0PkFADMpMeta || {},
    alias: detailNd0PkFADMpMeta?.alias || [],
    redirect: detailNd0PkFADMpMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/whitepage/detail.vue").then(m => m.default || m)
  },
  {
    name: listiTCMBmhwBAMeta?.name ?? "whitepage-list",
    path: listiTCMBmhwBAMeta?.path ?? "/whitepage/list",
    meta: listiTCMBmhwBAMeta || {},
    alias: listiTCMBmhwBAMeta?.alias || [],
    redirect: listiTCMBmhwBAMeta?.redirect || undefined,
    component: () => import("/data-test/workspace/Front_yb-switcher-website/pages/whitepage/list.vue").then(m => m.default || m)
  }
]